'use client';

import { SectionFormText_1 } from '@/components/text';
import { Heading } from '@/components/heading';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/custom/tooltipv2';
export default function QuickGuide() {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/kernel');
    router.prefetch('/contract');
    router.prefetch('/dapp');
  }, [router]);
  const [details, setDetails] = useState([{
    title: 'Kernel',
    href: '/kernel/register',
    description: 'A kernel is an on-chain or off-chain function created by a service provider to enhance the capabilities of a smart contract.',
    focus: true,
    video: 'https://rive.app/s/WWXUm7TrFkCkAw04b4FzXg/embed',
    link: 'https://docs.platform.lat/kernel/what-are-kernels'
  }, {
    title: 'Registered Smart Contract',
    href: '/contract/register',
    description: 'Smart contracts must implement the KRNL SDK interfaces to utilize the KRNL protocol (kOS) and leverage kernels for both cross-chain operations and integration with Web2 APIs.',
    focus: false,
    video: 'https://rive.app/s/yfw2a8j0EEGXhH0BXgNkQw/embed',
    link: 'https://docs.platform.lat/smart-contract/steps-for-smart-contract-developers'
  }, {
    title: 'dApp',
    href: '/dapp/register',
    description: 'A decentralized application will integrate a registered smart contract that has integrated kernels, to facilitate transactions with additional functionality.',
    focus: false,
    video: 'https://rive.app/s/Za-Y1ngPKUq4MIooHlhiSA/embed',
    link: 'https://docs.platform.lat/dapp/dapp-registry'
  }]);
  const handleFocus = (index: number) => {
    const updatedDetails = details.map((item, i) => ({
      ...item,
      focus: i === index
    }));
    setDetails(updatedDetails);
  };
  const focusedItem = details.find(item => item.focus);
  return <div className="mt-8 flex flex-col-reverse items-center justify-between gap-y-8 lg:mt-16 xl:gap-y-12 2xl:flex-row 2xl:gap-x-16 2xl:gap-y-0" data-sentry-component="QuickGuide" data-sentry-source-file="quick-guide.tsx">
      <div className="flex flex-col gap-8 lg:flex-row 2xl:flex-col">
        {details.map((item, index) => <div className={`min-h-[164px] w-full cursor-pointer select-none space-y-3 rounded-2xl border-2 border-shark-900 bg-transparent p-6 duration-500 ease-in-out sm:max-w-[469px] ${item.focus ? '' : 'opacity-40'}`} key={index} onClick={() => handleFocus(index)}>
            <div className="flex items-center gap-x-2">
              <Heading>{`${index + 1}. ${item.title}`}</Heading>
              <Tooltip placement={'top'}>
                <TooltipTrigger className="z-30 cursor-pointer">
                  <a href={item.link} target="_blank">
                    <InformationCircleIcon className="h-5 w-5 fill-shark-400" />
                  </a>
                </TooltipTrigger>
                <TooltipContent className="z-50 max-w-[calc(100vw-10px)] rounded-md bg-shark-800 px-2 py-1 text-white" arrowClassName="fill-shark-800">
                  More Info
                </TooltipContent>
              </Tooltip>
            </div>
            <SectionFormText_1>{item.description}</SectionFormText_1>
          </div>)}
      </div>
      <div className="duration-500 ease-in-out">
        {focusedItem && <iframe className="min-h-[232px] w-full md:h-[435px] md:w-[675px] lg:h-[290px] lg:w-[450px] xl:h-[435px] xl:w-[675px] 2xl:h-[580px] 2xl:w-[900px]" style={{
        border: 'none'
      }} src={focusedItem.video} allowFullScreen allow="autoplay" />}
      </div>
    </div>;
}