/**
 * TODO: Update this component to use your client-side framework's link
 * component. We've provided examples of how to do this for Next.js, Remix, and
 * Inertia.js in the Catalyst documentation:
 *
 * https://catalyst.tailwindui.com/docs#client-side-router-integration
 */

import * as Headless from '@headlessui/react';
import React, { forwardRef } from 'react';
import NextLink from 'next/link';
export const Link = forwardRef(function Link(props: {
  href: string;
} & React.ComponentPropsWithoutRef<'a'>, ref: React.ForwardedRef<HTMLAnchorElement>) {
  const {
    href
  } = props;
  if (href === null) {
    return <Headless.DataInteractive>
        <a {...props} ref={ref} />
      </Headless.DataInteractive>;
  }
  return <Headless.DataInteractive>
      <NextLink {...props} ref={ref} />
    </Headless.DataInteractive>;
});